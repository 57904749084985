var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { margin: "0 auto", width: "1200px", "margin-top": "20px" },
    },
    [
      _vm.pagesName === "BiddingConfirmOrdera"
        ? _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/home/HomePage" } } },
                [_vm._v("首页")]
              ),
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/home/Bidding" } } },
                [_vm._v("竞卖中心")]
              ),
              _c("el-breadcrumb-item", [_vm._v("竞卖订单")]),
            ],
            1
          )
        : _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/home/HomePage" } } },
                [_vm._v("首页")]
              ),
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/home/Mall" } } },
                [_vm._v("商品超市")]
              ),
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/home/Mall/ShoppingList" } } },
                [_vm._v("我的采购单")]
              ),
              _c("el-breadcrumb-item", [_vm._v("确认订单信息")]),
            ],
            1
          ),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticStyle: { background: "#fff", padding: "10px 20px 40px" } },
          [
            _c("h1", [_vm._v("确认订单信息")]),
            _c("h3", [
              _vm._v(
                _vm._s(
                  _vm.pagesName === "BiddingConfirmOrdera" ? "供应商" : "供应商"
                ) + "信息："
              ),
            ]),
            _vm.pagesName === "BiddingConfirmOrdera"
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "margin-bottom": "10px",
                        "margin-top": "10px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            flex: "0 0 80px",
                            "text-align": "right",
                            color: "#999",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.pagesName === "BiddingConfirmOrdera"
                                ? "供应商"
                                : "供应商"
                            ) + "："
                          ),
                        ]
                      ),
                      _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                        _vm._v(_vm._s(_vm.brandCartgoods2.clientName)),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "margin-bottom": "10px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            flex: "0 0 80px",
                            "text-align": "right",
                            color: "#999",
                          },
                        },
                        [_vm._v("联系人：")]
                      ),
                      _c("div", [
                        _vm._v(_vm._s(_vm.brandCartgoods2.contactName)),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "margin-bottom": "10px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            flex: "0 0 80px",
                            "text-align": "right",
                            color: "#999",
                          },
                        },
                        [_vm._v("电话：")]
                      ),
                      _c("div", [_vm._v(_vm._s(_vm.brandCartgoods2.phone))]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "border",
                      staticStyle: {
                        display: "flex",
                        "padding-bottom": "20px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            flex: "0 0 80px",
                            "text-align": "right",
                            color: "#999",
                          },
                        },
                        [_vm._v("地址：")]
                      ),
                      _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                        _vm._v(" " + _vm._s(_vm.brandCartgoods2.offAddr) + " "),
                      ]),
                    ]
                  ),
                ])
              : _vm.dtsGoods != undefined
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "margin-bottom": "10px",
                        "margin-top": "10px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            flex: "0 0 80px",
                            "text-align": "right",
                            color: "#999",
                          },
                        },
                        [_vm._v("供应商：")]
                      ),
                      _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                        _vm._v(_vm._s(_vm.dtsGoods.client_name)),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "margin-bottom": "10px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            flex: "0 0 80px",
                            "text-align": "right",
                            color: "#999",
                          },
                        },
                        [_vm._v("联系人：")]
                      ),
                      _c("div", [_vm._v(_vm._s(_vm.dtsGoods.users))]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "margin-bottom": "10px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            flex: "0 0 80px",
                            "text-align": "right",
                            color: "#999",
                          },
                        },
                        [_vm._v("电话：")]
                      ),
                      _c("div", [_vm._v(_vm._s(_vm.dtsGoods.tel))]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "border",
                      staticStyle: {
                        display: "flex",
                        "padding-bottom": "20px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            flex: "0 0 80px",
                            "text-align": "right",
                            color: "#999",
                          },
                        },
                        [_vm._v("地址：")]
                      ),
                      _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                        _vm._v(" " + _vm._s(_vm.dtsGoods.off_addr) + " "),
                      ]),
                    ]
                  ),
                ])
              : _c(
                  "div",
                  _vm._l(_vm.brandCartgoods, function (item, index) {
                    return _c("div", { key: index }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "margin-bottom": "10px",
                            "margin-top": "10px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                flex: "0 0 80px",
                                "text-align": "right",
                                color: "#999",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.pagesName === "BiddingConfirmOrdera"
                                    ? "供应商"
                                    : "供应商"
                                ) + "："
                              ),
                            ]
                          ),
                          _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                            _vm._v(_vm._s(item.brandName)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "margin-bottom": "10px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                flex: "0 0 80px",
                                "text-align": "right",
                                color: "#999",
                              },
                            },
                            [_vm._v("联系人：")]
                          ),
                          _c("div", [_vm._v(_vm._s(item.userName))]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "margin-bottom": "10px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                flex: "0 0 80px",
                                "text-align": "right",
                                color: "#999",
                              },
                            },
                            [_vm._v("电话：")]
                          ),
                          _c("div", [_vm._v(_vm._s(item.phone))]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "border",
                          staticStyle: {
                            display: "flex",
                            "padding-bottom": "20px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                flex: "0 0 80px",
                                "text-align": "right",
                                color: "#999",
                              },
                            },
                            [_vm._v("地址：")]
                          ),
                          _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                            _vm._v(" " + _vm._s(item.address) + " "),
                          ]),
                        ]
                      ),
                    ])
                  }),
                  0
                ),
            _c("h3", [_vm._v("买方信息：")]),
            _c("div", [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "margin-bottom": "10px",
                    "margin-top": "10px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "0 0 80px",
                        "text-align": "right",
                        color: "#999",
                      },
                    },
                    [_vm._v("买方：")]
                  ),
                  _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                    _vm._v(_vm._s(_vm.buyer.brandName)),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "margin-bottom": "10px",
                    "align-items": "center",
                  },
                },
                [
                  _vm._m(0),
                  _c("el-input", {
                    staticStyle: { flex: "0 0 500px" },
                    attrs: { placeholder: "请输入联系人姓名" },
                    model: {
                      value: _vm.buyer.uname,
                      callback: function ($$v) {
                        _vm.$set(_vm.buyer, "uname", $$v)
                      },
                      expression: "buyer.uname",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "margin-bottom": "10px",
                    "align-items": "center",
                  },
                },
                [
                  _vm._m(1),
                  _c("el-input", {
                    staticStyle: { flex: "0 0 500px" },
                    attrs: { placeholder: "请输入电话号码", maxlength: 11 },
                    model: {
                      value: _vm.buyer.tel,
                      callback: function ($$v) {
                        _vm.$set(_vm.buyer, "tel", $$v)
                      },
                      expression: "buyer.tel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "border",
                  staticStyle: {
                    display: "flex",
                    "padding-bottom": "20px",
                    "align-items": "center",
                  },
                },
                [
                  _vm._m(2),
                  _c("el-input", {
                    staticStyle: { flex: "0 0 500px" },
                    attrs: { placeholder: "请输入地址" },
                    model: {
                      value: _vm.buyer.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.buyer, "address", $$v)
                      },
                      expression: "buyer.address",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("h3", [_vm._v("商品信息")]),
            _vm.pagesName === "BiddingConfirmOrdera"
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "border",
                      staticStyle: {
                        position: "relative",
                        "padding-bottom": "90px",
                      },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.carts2,
                            "header-row-class-name": "table-header",
                            border: "",
                            fit: "",
                            "header-cell-style": {
                              background: "#F5f5f5",
                              height: "22px",
                              "line-height": "22px",
                              color: "#999",
                              "font-weight": 400,
                            },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsSn",
                              label: "货物",
                              width: "295",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "prodModels",
                              label: "规格",
                              width: "250",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "number",
                              label: "数量",
                              width: "295",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "price",
                              label: "金额(元)",
                              width: "300",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            right: "0px",
                            bottom: "20px",
                            "font-size": "14px",
                          },
                        },
                        [
                          _c("span", [_vm._v("合计：")]),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "10px" } },
                            [_vm._v(_vm._s(_vm.totalPrice) + "元")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { position: "relative", height: "120px" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { position: "relative", height: "40px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                position: "absolute",
                                right: "0",
                                top: "20px",
                                "font-size": "18px",
                              },
                            },
                            [
                              _c("span", [_vm._v("应付总计：")]),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#fc4500",
                                    "font-size": "18px",
                                    "margin-left": "10px",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.totalPrice))]
                              ),
                              _vm._v(" 元 "),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "sure-button",
                          on: { click: _vm.submit },
                        },
                        [_vm._v("确认下单")]
                      ),
                    ]
                  ),
                ])
              : _vm.carts != undefined
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "relative",
                        "padding-bottom": "90px",
                        "border-bottom": "2px dashed #999",
                      },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.carts,
                            "header-row-class-name": "table-header",
                            border: "",
                            fit: "",
                            "header-cell-style": {
                              background: "#F5f5f5",
                              height: "22px",
                              "line-height": "22px",
                              color: "#999",
                              "font-weight": 400,
                            },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsSn",
                              label: "货物",
                              width: "95",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "market",
                              label: "所在仓库",
                              "min-width": "120",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsName",
                              label: "品名",
                              width: "95",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "specifications",
                              label: "规格",
                              width: "120",
                              formatter: _vm.formatData,
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "stuff",
                              label: "材质",
                              width: "95",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "manufacturer",
                              label: "钢厂",
                              width: "95",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "number",
                              label: "数量",
                              width: "95",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "weight",
                              label: "重量(吨)",
                              width: "95",
                              formatter: _vm.formatWeight,
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "price",
                              label: "单价(元)",
                              width: "120",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "totalPrice",
                              label: "金额(元)",
                              width: "120",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            right: "20px",
                            bottom: "20px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { display: "flex", width: "400px" },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    flex: "auto",
                                    "text-align": "right",
                                  },
                                },
                                [_vm._v("合计：")]
                              ),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    "flex-basis": "150px",
                                    "text-align": "right",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.cartTotal.goodsAmount) + " 元"
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { position: "relative", height: "120px" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            right: "0",
                            top: "20px",
                          },
                        },
                        [
                          _c("span", [_vm._v("应付总计：")]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "inline-block",
                                width: "100px",
                                "text-align": "right",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { color: "#fc4500" } },
                                [_vm._v(_vm._s(_vm.cartTotal.goodsAmount))]
                              ),
                              _vm._v(" 元 "),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "sure-button",
                              on: { click: _vm.submit },
                            },
                            [_vm._v("确认下单")]
                          ),
                        ]
                      ),
                    ]
                  ),
                ])
              : _vm.dtsGoods != undefined
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "relative",
                        "padding-bottom": "90px",
                        "border-bottom": "2px dashed #999",
                      },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.goodInfo,
                            "header-row-class-name": "table-header",
                            border: "",
                            fit: "",
                            "header-cell-style": {
                              background: "#F5f5f5",
                              height: "22px",
                              "line-height": "22px",
                              color: "#999",
                              "font-weight": 400,
                            },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsSn",
                              label: "货物",
                              width: "95",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "entrepot",
                              label: "所在仓库",
                              "min-width": "120",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsName",
                              label: "品名",
                              width: "95",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "specifications",
                              label: "规格",
                              width: "120",
                              formatter: _vm.formatData,
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "stuff",
                              label: "材质",
                              width: "95",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "manufacturer",
                              label: "钢厂",
                              width: "95",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "number",
                              label: "数量",
                              width: "95",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "weight",
                              label: "重量(吨)",
                              width: "95",
                              formatter: _vm.formatWeight,
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "price",
                              label: "单价(元)",
                              width: "120",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "totalPrice",
                              label: "金额(元)",
                              width: "120",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            right: "20px",
                            bottom: "20px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { display: "flex", width: "400px" },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    flex: "auto",
                                    "text-align": "right",
                                  },
                                },
                                [_vm._v("合计：")]
                              ),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    "flex-basis": "150px",
                                    "text-align": "right",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.cartTotal.goodsAmount) + " 元"
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { position: "relative", height: "120px" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            right: "0",
                            top: "20px",
                          },
                        },
                        [
                          _c("span", [_vm._v("应付总计：")]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "inline-block",
                                width: "100px",
                                "text-align": "right",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { color: "#fc4500" } },
                                [_vm._v(_vm._s(_vm.cartTotal.goodsAmount))]
                              ),
                              _vm._v(" 元 "),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "sure-button",
                              on: { click: _vm.submit },
                            },
                            [_vm._v("确认下单")]
                          ),
                        ]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ]
        ),
        _vm.show
          ? _c("div", { staticClass: "bidding-status" }, [
              _vm.status === "success"
                ? _c(
                    "div",
                    { staticClass: "status-box" },
                    [
                      _vm._m(3),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.linkTo },
                        },
                        [_vm._v("我的订单")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.status === "error"
                ? _c(
                    "div",
                    { staticClass: "status-box" },
                    [
                      _vm._m(4),
                      _c(
                        "router-link",
                        { attrs: { to: "/home/Bidding" } },
                        [
                          _c("el-button", { attrs: { plain: "" } }, [
                            _vm._v("返回竞卖中心"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _c("CFCATool", {
        ref: "CFCARef",
        attrs: { id: "CFCAId" },
        on: {
          getCertInfo: function ($event) {
            _vm.certInfo = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: { flex: "0 0 80px", "text-align": "right", color: "#999" },
      },
      [
        _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
        _vm._v("联系人："),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: { flex: "0 0 80px", "text-align": "right", color: "#999" },
      },
      [
        _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
        _vm._v("电话："),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: { flex: "0 0 80px", "text-align": "right", color: "#999" },
      },
      [
        _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
        _vm._v("地址："),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "status-box-info" }, [
      _c("i", { staticClass: "el-icon-success" }),
      _c("div", { staticClass: "info" }, [
        _c("p", { staticClass: "txst" }, [_vm._v("确认订单成功")]),
        _c("p", { staticClass: "desc" }, [
          _vm._v("恭喜您，确认订单成功，前往我的订单查看订单信息"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "status-box-info" }, [
      _c("i", { staticClass: "el-icon-error" }),
      _c("div", { staticClass: "info" }, [
        _c("p", { staticClass: "txst" }, [_vm._v("确认订单失败")]),
        _c("p", { staticClass: "desc" }, [
          _vm._v("很遗憾，确认失败，请稍后重试"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }