<template>
  <div style="margin: 0 auto; width: 1200px; margin-top: 20px">
    <el-breadcrumb separator="/" v-if="pagesName === 'BiddingConfirmOrdera'">
      <el-breadcrumb-item :to="{path: '/home/HomePage'}">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{path: '/home/Bidding'}">竞卖中心</el-breadcrumb-item>
      <el-breadcrumb-item>竞卖订单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-breadcrumb separator="/" v-else>
      <el-breadcrumb-item :to="{path: '/home/HomePage'}">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{path: '/home/Mall'}">商品超市</el-breadcrumb-item>
      <el-breadcrumb-item :to="{path: '/home/Mall/ShoppingList'}">我的采购单</el-breadcrumb-item>
      <el-breadcrumb-item>确认订单信息</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="content">
      <div style="background: #fff; padding: 10px 20px 40px">
        <h1>确认订单信息</h1>

        <h3>{{ pagesName === 'BiddingConfirmOrdera' ? '供应商' : '供应商' }}信息：</h3>

        <div v-if="pagesName === 'BiddingConfirmOrdera'">
          <div style="display: flex; margin-bottom: 10px; margin-top: 10px">
            <div style="flex: 0 0 80px; text-align: right; color: #999">{{ pagesName === 'BiddingConfirmOrdera' ? '供应商' : '供应商' }}：</div>
            <div style="flex: 0 0 500px">{{ brandCartgoods2.clientName }}</div>
          </div>
          <div style="display: flex; margin-bottom: 10px">
            <div style="flex: 0 0 80px; text-align: right; color: #999">联系人：</div>
            <div>{{ brandCartgoods2.contactName }}</div>
          </div>
          <div style="display: flex; margin-bottom: 10px">
            <div style="flex: 0 0 80px; text-align: right; color: #999">电话：</div>
            <div>{{ brandCartgoods2.phone }}</div>
          </div>
          <div class="border" style="display: flex; padding-bottom: 20px">
            <div style="flex: 0 0 80px; text-align: right; color: #999">地址：</div>
            <div style="flex: 0 0 500px">
              {{ brandCartgoods2.offAddr }}
            </div>
          </div>
        </div>
        <div v-else-if="dtsGoods != undefined">
          <div style="display: flex; margin-bottom: 10px; margin-top: 10px">
            <div style="flex: 0 0 80px; text-align: right; color: #999">供应商：</div>
            <div style="flex: 0 0 500px">{{ dtsGoods.client_name }}</div>
          </div>
          <div style="display: flex; margin-bottom: 10px">
            <div style="flex: 0 0 80px; text-align: right; color: #999">联系人：</div>
            <div>{{ dtsGoods.users }}</div>
          </div>
          <div style="display: flex; margin-bottom: 10px">
            <div style="flex: 0 0 80px; text-align: right; color: #999">电话：</div>
            <div>{{ dtsGoods.tel }}</div>
          </div>
          <div class="border" style="display: flex; padding-bottom: 20px">
            <div style="flex: 0 0 80px; text-align: right; color: #999">地址：</div>
            <div style="flex: 0 0 500px">
              {{ dtsGoods.off_addr }}
            </div>
          </div>
        </div>

        <div v-else>
          <div v-for="(item, index) in brandCartgoods" :key="index">
            <div style="display: flex; margin-bottom: 10px; margin-top: 10px">
              <div style="flex: 0 0 80px; text-align: right; color: #999">{{ pagesName === 'BiddingConfirmOrdera' ? '供应商' : '供应商' }}：</div>
              <div style="flex: 0 0 500px">{{ item.brandName }}</div>
            </div>
            <div style="display: flex; margin-bottom: 10px">
              <div style="flex: 0 0 80px; text-align: right; color: #999">联系人：</div>
              <div>{{ item.userName }}</div>
            </div>
            <div style="display: flex; margin-bottom: 10px">
              <div style="flex: 0 0 80px; text-align: right; color: #999">电话：</div>
              <div>{{ item.phone }}</div>
            </div>
            <div class="border" style="display: flex; padding-bottom: 20px">
              <div style="flex: 0 0 80px; text-align: right; color: #999">地址：</div>
              <div style="flex: 0 0 500px">
                {{ item.address }}
              </div>
            </div>
          </div>
        </div>

        <h3>买方信息：</h3>
        <div>
          <div style="display: flex; margin-bottom: 10px; margin-top: 10px">
            <div style="flex: 0 0 80px; text-align: right; color: #999">买方：</div>
            <div style="flex: 0 0 500px">{{ buyer.brandName }}</div>
          </div>
          <div style="display: flex; margin-bottom: 10px; align-items: center">
            <div style="flex: 0 0 80px; text-align: right; color: #999"><span style="color: red">*</span>联系人：</div>
            <el-input v-model="buyer.uname" placeholder="请输入联系人姓名" style="flex: 0 0 500px"></el-input>
          </div>
          <div style="display: flex; margin-bottom: 10px; align-items: center">
            <div style="flex: 0 0 80px; text-align: right; color: #999"><span style="color: red">*</span>电话：</div>
            <el-input v-model="buyer.tel" placeholder="请输入电话号码" :maxlength="11" style="flex: 0 0 500px"></el-input>
          </div>
          <div class="border" style="display: flex; padding-bottom: 20px; align-items: center">
            <div style="flex: 0 0 80px; text-align: right; color: #999"><span style="color: red">*</span>地址：</div>
            <el-input v-model="buyer.address" placeholder="请输入地址" style="flex: 0 0 500px"></el-input>
          </div>
        </div>

        <h3>商品信息</h3>
        <div v-if="pagesName === 'BiddingConfirmOrdera'">
          <div class="border" style="position: relative; padding-bottom: 90px">
            <el-table
              :data="carts2"
              style="width: 100%"
              header-row-class-name="table-header"
              border
              fit
              :header-cell-style="{background: '#F5f5f5', height: '22px', 'line-height': '22px', color: '#999', 'font-weight': 400}"
            >
              <el-table-column prop="goodsSn" label="货物" width="295"> </el-table-column>
              <el-table-column prop="prodModels" label="规格" width="250"> </el-table-column>
              <el-table-column prop="number" label="数量" width="295"> </el-table-column>
              <el-table-column prop="price" label="金额(元)" width="300"> </el-table-column>
            </el-table>

            <div style="position: absolute; right: 0px; bottom: 20px; font-size: 14px">
              <span>合计：</span>
              <span style="margin-left: 10px">{{ totalPrice }}元</span>
            </div>
          </div>
          <div style="position: relative; height: 120px">
            <div style="position: relative; height: 40px">
              <div style="position: absolute; right: 0; top: 20px; font-size: 18px">
                <span>应付总计：</span>
                <span style="color: #fc4500; font-size: 18px; margin-left: 10px">{{ totalPrice }}</span> 元
              </div>
            </div>
            <div @click="submit" class="sure-button">确认下单</div>
          </div>
        </div>
        <div v-else-if="carts != undefined">
          <div style="position: relative; padding-bottom: 90px; border-bottom: 2px dashed #999">
            <el-table
              :data="carts"
              style="width: 100%"
              header-row-class-name="table-header"
              border
              fit
              :header-cell-style="{background: '#F5f5f5', height: '22px', 'line-height': '22px', color: '#999', 'font-weight': 400}"
            >
              <el-table-column prop="goodsSn" label="货物" width="95"> </el-table-column>
              <el-table-column prop="market" label="所在仓库" min-width="120"> </el-table-column>
              <el-table-column prop="goodsName" label="品名" width="95"> </el-table-column>
              <el-table-column prop="specifications" label="规格" width="120" :formatter="formatData"> </el-table-column>
              <el-table-column prop="stuff" label="材质" width="95"> </el-table-column>
              <el-table-column prop="manufacturer" label="钢厂" width="95"> </el-table-column>
              <el-table-column prop="number" label="数量" width="95"> </el-table-column>
              <!--<el-table-column prop="weightWay" label="计算方式" width="95">-->
              <!--</el-table-column>-->
              <el-table-column prop="weight" label="重量(吨)" width="95" :formatter="formatWeight"> </el-table-column>
              <el-table-column prop="price" label="单价(元)" width="120"> </el-table-column>
              <el-table-column prop="totalPrice" label="金额(元)" width="120"> </el-table-column>
            </el-table>

            <div style="position: absolute; right: 20px; bottom: 20px">
              <div style="display: flex; width: 400px">
                <p style="flex: auto; text-align: right">合计：</p>
                <p style="flex-basis: 150px; text-align: right">{{ cartTotal.goodsAmount }} 元</p>
              </div>
              <!--<div style="display:flex;width:400px">-->
              <!--    <p style="flex:auto;text-align:right">欧浦钢铁市场转货率(0.0元/吨)：</p>-->
              <!--    <p style="flex-basis:150px;text-align:right">+0.00 元</p>-->
              <!--</div>-->
            </div>
          </div>
          <div style="position: relative; height: 120px">
            <div style="position: absolute; right: 0; top: 20px">
              <span>应付总计：</span>
              <div style="display: inline-block; width: 100px; text-align: right">
                <span style="color: #fc4500">{{ cartTotal.goodsAmount }}</span> 元
              </div>
              <div @click="submit" class="sure-button">确认下单</div>
            </div>
          </div>
        </div>

        <div v-else-if="dtsGoods != undefined">
          <div style="position: relative; padding-bottom: 90px; border-bottom: 2px dashed #999">
            <el-table
              :data="goodInfo"
              style="width: 100%"
              header-row-class-name="table-header"
              border
              fit
              :header-cell-style="{background: '#F5f5f5', height: '22px', 'line-height': '22px', color: '#999', 'font-weight': 400}"
            >
              <el-table-column prop="goodsSn" label="货物" width="95"> </el-table-column>
              <el-table-column prop="entrepot" label="所在仓库" min-width="120"> </el-table-column>
              <el-table-column prop="goodsName" label="品名" width="95"> </el-table-column>
              <el-table-column prop="specifications" label="规格" width="120" :formatter="formatData"> </el-table-column>
              <el-table-column prop="stuff" label="材质" width="95"> </el-table-column>
              <el-table-column prop="manufacturer" label="钢厂" width="95"> </el-table-column>
              <el-table-column prop="number" label="数量" width="95"> </el-table-column>
              <!--<el-table-column prop="weightWay" label="计算方式" width="95">-->
              <!--</el-table-column>-->
              <el-table-column prop="weight" label="重量(吨)" width="95" :formatter="formatWeight"> </el-table-column>
              <el-table-column prop="price" label="单价(元)" width="120"> </el-table-column>
              <el-table-column prop="totalPrice" label="金额(元)" width="120"> </el-table-column>
            </el-table>

            <div style="position: absolute; right: 20px; bottom: 20px">
              <div style="display: flex; width: 400px">
                <p style="flex: auto; text-align: right">合计：</p>
                <p style="flex-basis: 150px; text-align: right">{{ cartTotal.goodsAmount }} 元</p>
              </div>
              <!--<div style="display:flex;width:400px">-->
              <!--    <p style="flex:auto;text-align:right">欧浦钢铁市场转货率(0.0元/吨)：</p>-->
              <!--    <p style="flex-basis:150px;text-align:right">+0.00 元</p>-->
              <!--</div>-->
            </div>
          </div>
          <div style="position: relative; height: 120px">
            <div style="position: absolute; right: 0; top: 20px">
              <span>应付总计：</span>
              <div style="display: inline-block; width: 100px; text-align: right">
                <span style="color: #fc4500">{{ cartTotal.goodsAmount }}</span> 元
              </div>
              <div @click="submit" class="sure-button">确认下单</div>
            </div>
          </div>
        </div>
      </div>

      <div class="bidding-status" v-if="show">
        <div class="status-box" v-if="status === 'success'">
          <div class="status-box-info">
            <i class="el-icon-success"></i>
            <div class="info">
              <p class="txst">确认订单成功</p>
              <p class="desc">恭喜您，确认订单成功，前往我的订单查看订单信息</p>
            </div>
          </div>
          <el-button @click="linkTo" type="primary">我的订单</el-button>
        </div>

                <div class="status-box" v-if="status === 'error'">
                    <div class="status-box-info">
                        <i class="el-icon-error"></i>
                        <div class="info">
                            <p class="txst">确认订单失败</p>
                            <p class="desc">很遗憾，确认失败，请稍后重试</p>
                        </div>
                    </div>
                    <router-link to="/home/Bidding">
                        <el-button plain>返回竞卖中心</el-button>
                    </router-link>
                </div>
            </div>

        </div>


        <CFCATool id="CFCAId" ref="CFCARef" @getCertInfo="certInfo = $event"/>
    </div>
</template>

<script>
import {createOrder, submitOrder, findClientInfoById, selectIsAutionById, createReqOrder, selectByPrimaryKey, autionDetail, buyNowGoods, submitBuyNow} from '../api';
import {getCartCount} from '../../Home/api';
    import CFCATool from '@/components/CFCATool';

export default {
  components: {
            CFCATool
        },
        data() {
            return {
                show: false,
                status: 'success',
                pagesName: '',
                autionInfoId: '',
                name: '',
                phone: '',
                place: '',
                tableData: [],
                list: {},
      //买方信息
      buyer: {
        uid: '',
        uname: '',
        brandName: '',
        tel: '',
        address: ''
                },
                //卖方
                brandCartgoods: {

                },
                brandCartgoods2: {

                },
                carts: undefined,
                carts2: undefined,
                totalPrice: undefined,
                cartTotal: {

                },
                certInfo: '',
      //立即购买
      buyNow: {
        goodId: '',
        productId: '',
        number: ''
      },
      //立即购买数据
      dtsGoods: undefined,
      goodInfo: []
    };
  },
  created() {
    let _this = this;
    this.pagesName = this.$route.query.pagesName;
    this.autionInfoId = this.$route.query.autionInfoId;
    this.clientId = this.$route.query.clientId;

    if (this.$route.query.goodId != undefined) {
      this.buyNow.goodId = this.$route.query.goodId;
      this.buyNow.productId = this.$route.query.productId;
      this.buyNow.number = this.$route.query.number;
      _this.buyNowGood();
      return;
    }

    //获取卖家信息
    if (this.pagesName == 'BiddingConfirmOrdera') {
      //获取买家卖家信息
      _this.getAutionDetail(this.autionInfoId);
      //获取竞卖信息
      _this.getAutionInfo();
    } else {
      //获取已选择订单
      _this.handleOrder();
    }
  },
    beforeDestroy() {
        localStorage.removeItem('certInfo');
    },
  methods: {
    linkTo() {
      localStorage.setItem('MenuType', 'BUY');
      this.$router.push({path: '/User/workbench'});
    },
    getAutionDetail() {
      let _this = this;
      autionDetail({autionId: _this.autionInfoId}).then(res => {
        //卖家信息
        _this.brandCartgoods2.clientName = res.data.sellder_client_name;
        _this.brandCartgoods2.contactName = res.data.sellder_name;
        _this.brandCartgoods2.phone = res.data.sellder_tel;
        _this.brandCartgoods2.offAddr = res.data.sellder_address;
        //买家信息
        _this.buyer.uid = localStorage.getItem('userId');
        _this.buyer.uname = res.data.buyder_name;
        _this.buyer.tel = res.data.buyder_tel;
        _this.buyer.address = res.data.buyder_address;
        _this.buyer.brandName = res.data.buyder_client_name;
      });
    },
    getAutionInfo() {
      let _this = this;
      selectIsAutionById({
        autionId: _this.autionInfoId
      }).then(res => {
        _this.carts2 = new Array();
        _this.carts2.push({
          goodsSn: res.data.tAutionInfo.prodName,
          number: res.data.tAutionDeal.proNum,
          price: res.data.tAutionDeal.amount,
          totalPrice: res.data.tAutionDeal.amount,
          id: res.data.tAutionDeal.id,
          prodModels: res.data.tAutionInfo.prodModels
        });
        _this.totalPrice = res.data.tAutionDeal.amount;
        // _this.getContactInfo(res.data.tAutionInfo.releaseAdmin)
      });
    },

    //获取供应商联系人信息
    getContactInfo(loginId) {
      let _this = this;
      selectByPrimaryKey({
        id: loginId
      }).then(res => {
        _this.$set(_this.brandCartgoods2, 'contactName', res.data.users);
        _this.$set(_this.brandCartgoods2, 'phone', res.data.tel);
      });
    },

    //获取当前登录用户信息
    getCurrentLoginInfo() {
      let _this = this;
      selectByPrimaryKey({
        id: localStorage.getItem('userId')
      }).then(res => {
        _this.buyer.uid = res.data.id;
        _this.buyer.uname = res.data.users;
        _this.buyer.tel = res.data.tel;
        _this.buyer.address = res.data.offAddr;
        _this.buyer.brandName = res.data.clientName;
      });
    },
    handleOrder() {
      createOrder().then(res => {
        this.buyer.uid = res.data.loginUser.id;
        this.buyer.uname = res.data.loginUser.users;
        this.buyer.tel = res.data.loginUser.tel;
        this.buyer.address = res.data.clientInfo.offAddr;
        this.buyer.brandName = res.data.clientInfo.clientName;
        this.carts = res.data.carts;
        this.brandCartgoods = res.data.brandCartgoods;
        this.cartTotal = res.data.cartTotal;
      });
    },
    buyNowGood() {
      buyNowGoods(this.buyNow).then(res => {
        this.buyer.uid = res.data.loginUser.id;
        this.buyer.uname = res.data.loginUser.users;
        this.buyer.tel = res.data.loginUser.tel;
        this.buyer.address = res.data.clientInfo.offAddr;
        this.buyer.brandName = res.data.clientInfo.clientName;
        this.cartTotal = res.data.cartTotal;
        this.dtsGoods = res.data.dtsGoods;
        this.dtsGoods['totalPrice'] = this.dtsGoods.number * this.dtsGoods.price;
        this.goodInfo.push(res.data.dtsGoods);
      });
    },
    submit() {
      var _this = this;
      if (_this.buyer.uname.length === 0) {
        this.$message('收货人不能为空');
        return;
      }
      if (_this.buyer.tel.length === 0) {
        this.$message('收货人电话不能为空');
        return;
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (!reg.test(_this.buyer.tel)) {
          this.$message('请输入正确的手机号');
          return;
        }
      }
      if (_this.buyer.address.length === 0) {
        this.$message('收货地址不能为空');
        return;
                }

                if(this.$refs.CFCARef.checkIsIE()){
                    // 获取证书序列码
                    if (!this.$refs.CFCARef.getCertInfo()) return;
                    // 检测是否读取证书序列码
                    if (!this.certInfo) return this.$message.info('未检测到证书信息');
                } else {
                    this.$refs.CFCARef.getCertInfo()
                    if (!this.certInfo) return ;
                    //  this.$message.info('未检测到证书信息')
                }
                localStorage.setItem('certInfo', this.certInfo);

                if (this.pagesName == 'BiddingConfirmOrdera') {
                    createReqOrder({
                        orderType: 'AU', //订单类型 AU=竞卖;RE=竞买
          consignee: _this.buyer.uname, //收货人姓名
          mobile: _this.buyer.tel, //收货人电话
          address: _this.buyer.address, //收货地址
          message: '', //订单留言
          productCount: _this.carts2[0].number, //产品数量
          goodsPrice: _this.carts2[0].totalPrice, //产品总费用
          freightType: '1', //配送方式0物流1自取
          autionId: _this.autionInfoId, //竞卖信息表主键(t_aution_info)  order_type=AU时必填
          autionDealId: _this.carts2[0].id //竞卖信息竞卖记录id(t_aution_deal)   order_type=AU时必填
        }).then(res => {
          if (res.errno == 0) {
            _this.$notify.success({
              title: '成功',
              message: '提交成功'
            });
            _this.show = true;
            _this.status = 'success';
            // this.$router.push({ path: '/home/Mall/Paymented', query: { orderid: 220}})
          }
        });
      } else if (this.$route.query.goodId != undefined) {
        //合并条件数组
        let sunbmitBuy = Object.assign(this.buyer, this.buyNow);
        //直接购买
        submitBuyNow(sunbmitBuy).then(res => {
          if (res.errno === 0) {
            this.$router.push({
              path: '/home/Mall/Paymented',
              query: {
                orderid: res.data.orderId,
                customer: 'buyder'
              }
            });
          }
        });
      } else {
        //购物车购买
        submitOrder(this.buyer).then(res => {
          if (res.errno === 0) {
            getCartCount().then(res => {
              this.$store.commit('setCarCount', res.data);
            });
            this.$router.push({
              path: '/home/Mall/Paymented',
              query: {
                orderid: res.data.orderId,
                customer: 'buyder'
              }
            });
          }
        });
      }
    },
    formatData(row) {
      let rowPerson = (row && row.specifications) || [];
      let arr = [];
      rowPerson.forEach(item => {
        //如果数据特别多的话,还可以在这里加if判断,仅展示规定的数据
        arr.push(item);
      });
      if (arr.length === 0) {
        return '无';
      }
      return arr.join(', ');
    },
    formatWeight(row) {
      if (row.weight == null || row.weight == 0) {
        return '-';
      } else {
        return row.weight;
      }
    },
    //竞卖
    toPaymented() {
      this.$router.push({
        path: '/home/Bidding/Paymented',
        query: {}
      });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.table-header {
  .is-leaf {
    background: #e8e8e8;
    color: #666;
    text-align: center;
  }
}

/deep/.cell {
  text-align: center;
}

.content {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  overflow: hidden;
  position: relative;

  .bidding-status {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    .status-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .status-box-info {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .el-icon-success {
          font-size: 44px;
          color: #55c176;
          margin-right: 10px;
        }

        .el-icon-error {
          font-size: 44px;
          color: #d91516;
          margin-right: 10px;
        }

        .txst {
          font-size: 16px;
        }

        .desc {
          font-size: 13px;
          margin-top: 4px;
        }
      }
    }
  }
}

.border {
  border-bottom: 1px solid #ebeef5;
}

.sure-button {
  position: absolute;
  top: 60px;
  right: 0;
  cursor: pointer;
  background: linear-gradient(to right, #f57838, #ff4603);
  line-height: 60px;
  padding: 0 15px;
  color: #fff;
  font-size: 20px;
  border-radius: 4px;
}
</style>
